<template>
  <div>
    <CSTabBar
        :tabbar="tabbar"
        :checkedTab="checkedTab"
        @changeTabBar="changeTabBar"
    />

    <div class="filter-panel" v-show="checkedTab != TAB_BAR_TYPE.CONFIG">
      <input
          type="text"
          class="cs-input"
          v-model="queryParams.keyword"
          style="width: 160px"
          placeholder="搜索企业名称/联系人"
      />
      <CSSelect v-show="checkedTab !== TAB_BAR_TYPE.CUSTOMERPOOLL">
        <select v-model="queryParams.industry">
          <option value="">全部行业</option>
          <option
              v-for="(val, type) in INDUSTRY_TYPES"
              :key="type"
              :value="type"
          >
            {{ val }}
          </option>
        </select>
      </CSSelect>
      <CSSelect v-show="checkedTab !== TAB_BAR_TYPE.CUSTOMERPOOLL">
        <select v-model="queryParams.source">
          <option value="">全部客户来源</option>
          <option
              v-for="(val, type) in CUSTOMER_SOURCES"
              :key="type"
              :value="type"
          >
            {{ val }}
          </option>
        </select>
      </CSSelect>
      <CSSelect v-show="checkedTab == TAB_BAR_TYPE.PRIVATE">
        <select v-model="queryParams.intention">
          <option value="">全部意向程度</option>
          <option value="1">A</option>
          <option value="2">B</option>
          <option value="3">C</option>
          <option value="4">D</option>
          <option value="5">E</option>
        </select>
      </CSSelect>
      <CSSelect
          v-show="
          ![
            TAB_BAR_TYPE.PUBLIC,
            TAB_BAR_TYPE.TEMPORARY_PRIVATE,
            TAB_BAR_TYPE.CUSTOMERPOOLL
          ].includes(+checkedTab)
        "
      >
        <select v-model="queryParams.ownerId">
          <option value="">全部员工</option>
          <option
              v-for="(staff, index) in staffList"
              :key="`${staff.id}_${index}`"
              :value="staff.id"
          >
            {{ staff.name }}
          </option>
        </select>
      </CSSelect>

      <button
          class="btn btn-primary"
          style="margin-left: 10px"
          @click="searchData()"
      >
        查询
      </button>
    </div>

    <div class="result-panel" v-if="checkedTab == TAB_BAR_TYPE.PUBLIC">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                @click="openUploadExcel"
            >
              Excel上传客户
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>企业名称</th>
            <th>所属行业</th>
            <th>客户来源</th>
            <th>最新跟进内容</th>
            <th>最新跟进时间</th>
            <th>联系人</th>
            <th>电话</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="publicCustomerList.length > 0">
            <tr v-for="customer in publicCustomerList" :key="customer.leadId">
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 1, 1, customer.userId)
                  "
                >{{ customer.company }}</span
                >
              </td>
              <td>
                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
              </td>
              <td>
                {{ CUSTOMER_SOURCES[customer.source] || "-" }}
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 2, 1, customer.userId)
                  "
                    v-if="customer.latestFollowUpLog"
                >
                  {{ customer.latestFollowUpLog }}
                </span>
                <template v-else>-</template>
              </td>
              <td>
                <template v-if="customer.latestFollowUpTime">
                  {{
                    customer.latestFollowUpTime.substring(
                        0,
                        customer.latestFollowUpTime.length - 3
                    )
                  }}
                </template>
                <template v-else>-</template>
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 3, 1, customer.userId)
                  "
                >
                  {{ customer.contactsName || "-" }}
                </span>
              </td>
              <td>
                {{ customer.contactsPhone || "-" }}
              </td>
              <td>
                <button
                    class="btn btn-primary"
                    @click="deleteCustomer(customer.leadId)"
                >
                  删除
                </button>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <!-- 公有客户 -->
      <Pagination v-if="TAB_BAR_TYPE.CONFIG !== +checkedTab"></Pagination>
    </div>
    <!-- 客户池 -->
    <div class="result-panel" v-if="checkedTab === TAB_BAR_TYPE.CUSTOMERPOOLL">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>提交时间</th>
            <th>企业名称</th>
            <th>联系人</th>
            <th>电话</th>
            <th>客户来源</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="sitePoolList.length > 0">
            <tr v-for="item in sitePoolList" :key="item.contactsId">
              <td style="min-width: 150px">{{ item.createTime }}</td>
              <td>{{ item.company || "-" }}</td>
              <td>{{ item.contactsName || "-" }}</td>
              <td>{{ item.contactsPhone || "-" }}</td>
              <td>{{ CUSTOMER_SOURCES[item.source] || "-" }}</td>
              <td>
                <div class="btn-group">
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 100px"
                  >
                    <li @click="openAddCommonTable(item)">
                      <a style="width: 100%">分配</a>
                    </li>
                    <li @click="openCommonAssessmentItem(item)">
                      <a style="width: 100%">删除</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <!-- <CustomerPool></CustomerPool> -->
      <!-- 公有客户 -->
      <Pagination v-if="TAB_BAR_TYPE.CUSTOMERPOOLL == +checkedTab"></Pagination>
    </div>

    <div
        class="result-panel"
        v-if="checkedTab == TAB_BAR_TYPE.TEMPORARY_PRIVATE"
    >
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>企业名称</th>
            <th>所属行业</th>
            <th>客户来源</th>
            <th>最新跟进内容</th>
            <th>最新跟进时间</th>
            <th>联系人</th>
            <th>电话</th>
            <th>所属员工</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="tempPrivateCustomerList.length > 0">
            <tr
                v-for="customer in tempPrivateCustomerList"
                :key="customer.leadId"
            >
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 1, 2, customer.ownerId)
                  "
                >{{ customer.company }}</span
                >
              </td>
              <td>
                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
              </td>
              <td>
                {{ CUSTOMER_SOURCES[customer.source] || "-" }}
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 2, 2, customer.ownerId)
                  "
                    v-if="customer.latestFollowUpLog"
                >
                  {{ customer.latestFollowUpLog }}
                </span>
                <template v-else>-</template>
              </td>
              <td>
                <template v-if="customer.latestFollowUpTime">
                  {{
                    customer.latestFollowUpTime.substring(
                        0,
                        customer.latestFollowUpTime.length - 3
                    )
                  }}
                </template>
                <template v-else>-</template>
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 3, 2, customer.ownerId)
                  "
                >
                  {{ customer.contactsName || "-" }}
                </span>
              </td>
              <td>
                {{ customer.contactsPhone || "-" }}
              </td>
              <td>{{ customer.ownerName || "-" }}</td>
              <td>
                <button
                    class="btn btn-primary"
                    @click="deleteCustomer(customer.leadId)"
                >
                  删除
                </button>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <!-- 临时获取客户 -->
      <Pagination
          v-if="![TAB_BAR_TYPE.PUBLIC, TAB_BAR_TYPE.CONFIG].includes(+checkedTab)"
      ></Pagination>
    </div>
    <div class="result-panel" v-if="checkedTab == TAB_BAR_TYPE.PRIVATE">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                @click="openTransferDialog(2)"
            >
              转移客户
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>
              <input
                  type="checkbox"
                  @change="pickerAllCustomer"
                  :class="[
                  {
                    notAllChecked:
                      checkedTransfer[currentPage] &&
                      checkedTransfer[currentPage].length !== 0 &&
                      checkedTransfer[currentPage].length <
                        privateCustomerList.length,
                  },
                ]"
                  :checked="
                  checkedTransfer[currentPage] &&
                  checkedTransfer[currentPage].length ===
                    privateCustomerList.length
                "
              />
              <span>全选</span>
            </th>
            <th>首次接触时间</th>
            <th>企业名称</th>
            <th>所属行业</th>
            <th>客户来源</th>
            <th>
              <span style="vertical-align: middle; margin-right: 5px"
              >意向程度</span
              >
              <img
                  @click="lookLeadsLevels"
                  src="../../assets/icon/question.png"
                  alt=""
              />
            </th>
            <th>最新跟进内容</th>
            <th>最新跟进时间</th>
            <th>剩余掉落天数</th>
            <th>联系人</th>
            <th>电话</th>
            <th>意向房间</th>
            <th>认租审核状态</th>
            <th>备注</th>
            <th>所属员工</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template
              v-if="privateCustomerList && privateCustomerList.length > 0"
          >
            <tr v-for="customer in privateCustomerList" :key="customer.leadId">
              <td>
                <input
                    type="checkbox"
                    :checked="
                    checkedTransfer[currentPage] &&
                    checkedTransfer[currentPage].includes(customer.leadId)
                  "
                    @change="pickerCustomer(customer.leadId)"
                />
              </td>
              <td>
                {{ customer. firstCommunicationTime || "-" }}
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 1, 2, customer.userId)
                  "
                >
                  {{ customer.company }}
                </span>
              </td>
              <td>
                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
              </td>
              <td>
                {{ CUSTOMER_SOURCES[customer.source] || "-" }}
              </td>
              <td>
                {{ leadsLevel[customer.intention] || "-" }}
              </td>
              <td>
                <span
                    class="allow-click"
                    v-if="
                    customer.latestFollowUpLog &&
                    customer.latestFollowUpLog.length > 0
                  "
                    @click="
                    toCustomerInfoPage(customer.leadId, 2, 2, customer.userId)
                  "
                >
                  {{ customer.latestFollowUpLog }}
                </span>
                <template v-else> -</template>
              </td>
              <td>
                <template v-if="customer.latestFollowUpTime">
                  {{
                    customer.latestFollowUpTime.substring(
                        0,
                        customer.latestFollowUpTime.length - 3
                    )
                  }}
                </template>
                <template v-else>-</template>
              </td>
              <td>{{ customer.expiredTime | expiredDay }}</td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 3, 2, customer.userId)
                  "
                >
                  {{ customer.contactsName || "-" }}
                </span>
              </td>
              <td>
                {{ customer.contactsPhone || "-" }}
              </td>
              <td>
                <div
                    @click="lookIntentionRooms(1, customer.intentionRooms)"
                    class="allow-click"
                    style="max-width: 200px"
                    v-if="
                    customer.intentionRooms &&
                    customer.intentionRooms.length > 0
                  "
                >
                  {{ customer.intentionRooms | getIntentionRoomNames }}
                </div>
                <template v-else>-</template>
              </td>
              <td>
                <template
                    v-if="
                    customer.subscribeAuditComment &&
                    customer.subscribeAuditComment !== ''
                  "
                >
                  <span
                      class="allow-click"
                      @click="
                      lookSubscribeAuditComment(
                        customer.subscribeAuditComment,
                        customer.subscribeAuditState
                      )
                    "
                  >{{
                      subscribeAuditState[customer.subscribeAuditState] || "-"
                    }}</span
                  >
                </template>
                <template v-else>
                  {{ subscribeAuditState[customer.subscribeAuditState] || "-" }}
                </template>
              </td>
              <td>
                <span
                    class="allow-click"
                    v-if="customer.comment"
                    @click="lookCustomerComment(customer.comment)"
                >
                  查看
                </span>
                <template v-else>-</template>
              </td>
              <td>
                {{ customer.userName || "-" }}
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination
          v-if="![TAB_BAR_TYPE.PUBLIC, TAB_BAR_TYPE.CONFIG].includes(+checkedTab)"
      ></Pagination>
    </div>

    <div class="result-panel" v-if="checkedTab == TAB_BAR_TYPE.SUBSCRIPTION">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                @click="openTransferDialog(1)"
            >
              转移客户
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>
              <input
                  type="checkbox"
                  :checked="
                  checkedTransfer[currentPage] &&
                  checkedTransfer[currentPage].length ===
                    subscriptionCustomerList.length
                "
                  :class="[
                  {
                    notAllChecked:
                      checkedTransfer[currentPage] &&
                      checkedTransfer[currentPage].length !== 0 &&
                      checkedTransfer[currentPage].length <
                        subscriptionCustomerList.length,
                  },
                ]"
                  @change="pickerAllCustomer"
              />
              <span>全选</span>
            </th>
            <th>审核通过时间</th>
            <th>企业名称</th>
            <th>认租房间</th>
            <th>认租定金</th>
            <th>认租保留期</th>
            <th>所属行业</th>
            <th>客户来源</th>
            <th>联系人</th>
            <th>电话</th>
            <th>所属员工</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template
              v-if="
              subscriptionCustomerList && subscriptionCustomerList.length > 0
            "
          >
            <tr
                v-for="customer in subscriptionCustomerList"
                :key="customer.leadId + '-' + customer.subscriptionId"
            >
              <td>
                <input
                    type="checkbox"
                    :checked="
                    checkedTransfer[currentPage] &&
                    checkedTransfer[currentPage].includes(customer.leadId)
                  "
                    @change="pickerCustomer(customer.leadId)"
                />
              </td>
              <td class="full-date-td">{{ customer.auditTime }}</td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 1, 3, customer.userId)
                  "
                >
                  {{ customer.company }}
                </span>
              </td>
              <td>
                <div
                    class="allow-click"
                    style="max-width: 200px"
                    v-if="
                    customer.intentionRooms &&
                    customer.intentionRooms.length > 0
                  "
                    @click="lookIntentionRooms(2, customer.intentionRooms)"
                >
                  {{ customer.intentionRooms | getIntentionRoomNames }}
                </div>
                <template v-else> -</template>
              </td>
              <td>{{ customer.moneyAmount }}</td>
              <td>{{ customer.retentionDays }}</td>
              <td>
                {{ INDUSTRY_TYPES[customer.industry] || "-" }}
              </td>
              <td>
                {{ CUSTOMER_SOURCES[customer.source] }}
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="
                    toCustomerInfoPage(customer.leadId, 3, 3, customer.userId)
                  "
                    v-if="customer.contactsName"
                >{{ customer.contactsName }}</span
                >
                <template v-else>-</template>
              </td>
              <td>{{ customer.contactsPhone || "-" }}</td>
              <td>
                {{ customer.userName || "-" }}
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination
          v-if="![TAB_BAR_TYPE.PUBLIC, TAB_BAR_TYPE.CONFIG].includes(+checkedTab)"
      ></Pagination>
    </div>

    <div class="tab-view" v-if="checkedTab == TAB_BAR_TYPE.CONFIG">
      <div class="field-item">
        <div class="field-item-label">私有客户掉落天数</div>
        <div>
          <CSSelect height="30px">
            <select
                v-model="crmConfig[1].value"
                :class="{ selected: crmConfig[1].value !== '' }"
            >
              <option value="" selected>请选择</option>
              <option v-for="n in 30" :value="n" :key="n">
                {{ n }}
              </option>
              <option :value="60">
                {{ 60 }}
              </option>
              <option :value="90">
                {{ 90 }}
              </option>
              <option :value="180">
                {{ 180 }}
              </option>
              <option :value="365">
                {{ 365 }}
              </option>
            </select>
          </CSSelect>
          <button class="btn btn-primary" @click="saveCrmConfig(1)">
            保存
          </button>
        </div>
      </div>
      <div class="field-item">
        <div class="field-item-label">添加私有客户上限</div>
        <div>
          <CSSelect height="30px">
            <select
                v-model="crmConfig[2].value"
                :class="{ selected: crmConfig[2].value !== '' }"
            >
              <option value="" selected>请选择</option>
              <template v-for="n in 650">
                <option :value="n" :key="n" v-if="n >= 5">
                  {{ n }}
                </option>
              </template>
            </select>
          </CSSelect>
          <button class="btn btn-primary" @click="saveCrmConfig(2)">
            保存
          </button>
        </div>
      </div>
    </div>

    <CSDialog
        :dialogVisible="transferLeadDialog.visible"
        :dialogWidth="550"
        dialog-title="转移客户"
        @onClose="transferLeadDialog.onClose"
        @onConfirm="transferLeadDialog.onConfirm"
    >
      <template v-slot:dialog-content>
        <div class="upload-excel-dialog">
          <div class="form-item">
            <div class="form-item-label">选择员工</div>
            <div>
              <CSSelect height="40px" i-width="36px">
                <select v-model="transferLeadDialog.toUser">
                  <option value="" selected disabled>请选择</option>
                  <option
                      v-for="(staff, index) in staffList"
                      :key="`${staff.id}_${index}`"
                      :value="staff.id"
                  >
                    {{ staff.name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- 上传文件弹窗 -->
    <CSDialog
        :dialogVisible="uploadExcelDialog.visible"
        :dialogWidth="550"
        dialog-title="上传客户"
        @onClose="closeUploadExcel"
        @onConfirm="uploadExcel"
    >
      <template v-slot:dialog-content>
        <div class="upload-excel-dialog">
          <div class="form-item">
            <div class="form-item-label">选择Excel</div>
            <div>
              <input
                  type="file"
                  hidden
                  id="uploadExcel"
                  @change="chooseExcel($event)"
              />
              <input
                  type="text"
                  class="cs-input"
                  readonly
                  v-model="uploadExcelDialog.file.name"
                  placeholder="请选择"
              />
              <button
                  class="btn btn-primary"
                  style="margin-left: 12px"
                  @click="triggerChooseExcel"
              >
                浏览
              </button>
            </div>
          </div>
<!--          <div class="form-item">-->
<!--            <div class="form-item-label">客户来源</div>-->
<!--            <div>-->
<!--              <CSSelect height="40px">-->
<!--                <select v-model="uploadExcelDialog.source">-->
<!--                  <option value="" selected disabled>请选择</option>-->
<!--                  <option-->
<!--                      v-for="(name, source) in CUSTOMER_SOURCES"-->
<!--                      :value="source"-->
<!--                      :key="source"-->
<!--                  >-->
<!--                    {{ name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </CSSelect>-->
<!--            </div>-->
<!--          </div>-->
          <div class="form-item">
            <div class="form-item-label">模版</div>
            <div>
              <a
                  class="allow-click"
                  href="https://cosmoss-img.oss-cn-beijing.aliyuncs.com/excel/%E4%B8%8A%E4%BC%A0%E5%AE%A2%E6%88%B7.xlsx"
                  target="_blank"
              >下载</a
              >
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

    <!-- 分配 -->
    <CSDialog
        :dialogVisible="sitepool.visible"
        :dialogWidth="550"
        dialog-title="分配"
        @onClose="closeUpsitepool"
        @onConfirm="uploadsitepool"
        dialogConfirmBtnText="确定"
    >
      <template v-slot:dialog-content>
        <div class="upload-excel-dialog">
          <div class="form-item">
            <div class="form-item-label" style="width: 120px">招商部员工</div>
            <div>
              <CSSelect height="40px">
                <select v-model="staffId">
                  <option value="" selected disabled>请选择</option>
                  <option
                      v-for="item in employeeList"
                      :value="item.id"
                      :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import {ORG_LEVEL, JOB_LEVEL} from "@/constant";
import Pagination from "@/components/Pagination";

import {
  saveConfigLeaseCrmUrl,
  queryCrmConfigUrl,
  uploadExcelUrl,
  queryOpportunityUrl,
  querySubscriptionsUrl,
  queryDepartmentStaffUrl,
  deleteCustomerUrl,
  transferLeadUrl,
  queryPublicLeadsUrl,
  queryDepartmentUrl,
  queryTempCustomerPoolUrl,
  bigDataEquipmentCountUrl,
  allotSitesUserUrl,
  lockedToOpportunityUrl,
  getCustomerLeadsUrl,
  queryPublicCustomerUrl, assigningPublicClientUrl,
} from "@/requestUrl";
import {CUSTOMER_SOURCES, INDUSTRY_TYPES, DEPARTMENT_TYPES} from "@/constant";
import dayjs from "dayjs";
import CSTable from "@/components/common/CSTable";
import CustomerPool from "./CustomerPool.vue";

const TAB_BAR_TYPE = {
  PUBLIC: 1,
  CUSTOMERPOOLL: 2,
  TEMPORARY_PRIVATE: 3,
  PRIVATE: 4,
  SUBSCRIPTION: 5,
  CONFIG: 6,
};

export default {
  name: "CustomerManage",
  components: {
    CSTable,
    CSTabBar,
    CSSelect,
    CSDialog,
    Pagination,
    CustomerPool,
  },
  filters: {
    expiredDay(val) {
      if (!val) {
        return "-";
      }
      const second = dayjs(val).diff(dayjs(), "millisecond") / 1000,
          day = Math.floor(second / 60 / 60 / 24),
          hour = Math.floor((second / 60 / 60) % 24),
          timeArr = [];
      if (day !== 0) {
        timeArr.push(day.toString().padStart(2, "0") + "天");
      }
      if (hour !== 0) {
        timeArr.push(hour.toString().padStart(2, "0") + "小时");
      }
      return timeArr.join("");
    },
    // 返回意向房间名称
    getIntentionRoomNames(rooms) {
      return rooms
          .map((room) => room.buildingName + "-" + room.roomName)
          .toString();
    },
  },
  async created() {
    window.addEventListener("keydown", this.searchDataDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.searchDataDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.searchDataDown);
  },
  data() {
    return {
      load: true,
      leadIds: "",
      lockedLeadIds: "",
      lockedLeadId: "",
      staffId: "",
      //员工列表
      employeeList: [],
      //池塘
      sitepool: {
        visible: false,
      },
      filterHeight: 0,
      subscribeAuditState: {
        0: "未认租",
        1: "待上级审核",
        2: "上级审核驳回",
        3: "待财务审核",
        4: "财务审核驳回",
        5: "审核通过",
      },
      CUSTOMER_SOURCES,
      INDUSTRY_TYPES,
      TAB_BAR_TYPE,
      checkedTransfer: {},
      currentPage: 1,
      tabbar: {
        [TAB_BAR_TYPE.PUBLIC]: {
          name: "公有客户",
        },
        [TAB_BAR_TYPE.CUSTOMERPOOLL]: {
          name: "网站收集客户池",
        },
        [TAB_BAR_TYPE.TEMPORARY_PRIVATE]: {
          name: "临时获取客户",
        },
        [TAB_BAR_TYPE.PRIVATE]: {
          name: "私有客户",
        },
        [TAB_BAR_TYPE.SUBSCRIPTION]: {
          name: "认租客户",
        },
        [TAB_BAR_TYPE.CONFIG]: {
          name: "设置",
        },
      },
      leadsLevel: {
        1: "A",
        2: "B",
        3: "C",
        4: "D",
        5: "E",
      },
      //  选中的tab
      checkedTab: 1,
      uploadExcelDialog: {
        visible: false,
        file: {name: ""},
        source: "",
        regionCode: this.$vc.getCurrentRegion().code,
      },
      crmConfig: {
        1: {
          value: "",
        },
        2: {
          value: "",
        },
        3: {
          value: 50,
        },
      },
      queryParams: {
        regionCode: this.$vc.getCurrentRegion().code,
        keyword: "",
        source: "",
        industry: "",
        intention: "",
        orderBy: 1,
        target: "",
        ownerId: "",
      },
      publicCustomerList: [],
      sitePoolList: [], //网站收集客户池
      tempPrivateCustomerList: [],
      privateCustomerList: [],
      subscriptionCustomerList: [],
      staffList: [],
      transferLeadDialog: {
        visible: false,
        toUser: "",
        onClose: () => {
          this.transferLeadDialog.visible = false;
        },
        onConfirm() {
        },
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  async mounted() {

    const staffInfo = this.$vc.getCurrentStaffInfo();
    let departmentCode = staffInfo.departmentCode,
        staffList = [];
    if (
        [DEPARTMENT_TYPES.DIRECTOR, DEPARTMENT_TYPES.MANAGER].includes(
            staffInfo.dutyType
        ) &&
        staffInfo.jobLevel === JOB_LEVEL.SUPERVISOR
    ) {
      const departmentList = await this.queryDepartmentList(
          ORG_LEVEL.DEPARTMENT,
          1,
          100,
          staffInfo.companyCode
      );
      for (let val of departmentList) {
        if (val.dutyType == staffInfo.dutyType) {
          departmentCode = val.code;
          const groupList = await this.queryDepartmentList(
              ORG_LEVEL.GROUP,
              1,
              100,
              val.code
          );
          for (let group of groupList) {
            // staffList.push(...await this.getStaffList(group.code));
          }
          return;
        }
      }
    } else {
      const groupList = await this.queryDepartmentList(
          ORG_LEVEL.GROUP,
          1,
          100,
          departmentCode
      );
      for (let group of groupList) {
        // staffList.push(...await this.getStaffList(group.code));
      }
    }
    staffList.push(...(await this.getStaffList(departmentCode)));
    this.staffList = staffList;
    this.changeTabBar(this.checkedTab, "checkedTab");

    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.searchData(pageNo);
    });

    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  methods: {
    async querySelect() {
    },
    //g关闭分配
    closeUpsitepool() {
      this.sitepool.visible = false;
    },
    //保存分配SSS
    async uploadsitepool() {
      if (this.staffId === null || this.staffId === '') {
        this.$vc.toast("请选择员工");
        return;
      }

      await this.$fly.get(
          assigningPublicClientUrl,
          {
            userId: this.staffId,
            leadId: this.lockedLeadId
          }
      ).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.$vc.toast("转移成功");
        this.sitepool.visible = false;
        this.staffId = "";
        this.querySitePool();
      });
      // console.log(1);
      // this.$fly
      //   .post(transferLeadUrl, {
      //     regionCode: this.$vc.getCurrentRegion().code,
      //     leadIds: [this.lockedLeadId],
      //     toUser: this.staffId,
      //   })
      //   .then((res) => {
      //     console.log(2);
      //     if (res.code !== 0) {
      //       return;
      //     }
      //     this.$vc.toast("转移成功");
      //     this.sitepool.visible = false;
      //     this.staffId = "";
      //     this.querySitePool();
      //   });
      // this.$fly
      //   .post(allotSitesUserUrl, {
      //     regionCode: this.$vc.getCurrentRegion().code, //必填
      //     staffId: this.staffId, //必填
      //     lockedLeadId: this.lockedLeadId, //必填
      //   })
      //   .then((res) => {
      //     if (res.code != 0) {
      //       return;
      //     }
      //     this.$vc.toast("设置成功");
      //     this.sitepool.visible = false;
      //   });
    },
    //分配客户池
    openAddCommonTable(item) {
      this.lockedLeadId = item.leadId;

      this.lockedLeadIds = item.lockId;
      this.sitepool.visible = true;
    },
    //删除客户池
    openCommonAssessmentItem(item) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除吗？",
        onConfirm: () => {
          this.$fly
              .post(deleteCustomerUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                leadId: item.leadId,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast("删除成功");
                this.searchData();
                this.$CSDialog.instance.closeDialog();
              });
        },
      });
    },
    /**
     * 查看审核评论
     * @param {String} comment 评论内容
     * @param {Number} status 审核状态
     * */
    searchDataDown(e) {
      if (e.keyCode == 13) {
        this.searchData();
      }
    },
    lookSubscribeAuditComment(comment, status) {
      this.$CSDialog({
        title: [2, 4].includes(status) ? "驳回" : "通过",
        message: comment,
      });
    },
    /**
     * 查看房间
     * @param {Number} type 1:意向房间   2:认租房间
     * @param {Array} rooms 选中的房间
     * */
    lookIntentionRooms(type, rooms) {
      let roomHtml = "";
      rooms.forEach((room) => {
        roomHtml += `<p>${room.buildingName}-${room.roomName}</p>`;
      });
      this.$CSDialog.alert({
        title: `${type === 1 ? "意向" : "认租"}房间`,
        width: "400px",
        messageHtml: `
                <div class="comment-panel text-center">
                    <div class="item text-center">
                        <div style="max-width: 100%;">
                            ${roomHtml}
                        </div>
                    </div>
                </div>
                `,
      });
    },
    /**
     * 查看备注
     * @param {String} comment 备注信息
     *  */
    lookCustomerComment(comment) {
      this.$CSDialog.alert({
        title: "备注",
        message: comment,
      });
    },
    // 打开转移弹窗
    openTransferDialog(type) {
      this.transferLeadDialog.visible = true;
      this.transferLeadDialog.toUser = "";
      const leadIds = [];
      Object.values(this.checkedTransfer).forEach((val) =>
          leadIds.push(...val)
      );
      this.transferLeadDialog.onConfirm = () => {
        this.$fly
            .post(transferLeadUrl, {
              regionCode: this.$vc.getCurrentRegion().code,
              leadIds: leadIds,
              toUser: this.transferLeadDialog.toUser,
              type
            })
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              this.$vc.toast("转移成功");
              this.transferLeadDialog.onClose();
              this.checkedTransfer = {};
              this.searchData();
            });
      };
    },
    // 选择全部的客户
    pickerAllCustomer() {
      const tabRowList = {
        [TAB_BAR_TYPE.PRIVATE]: this.privateCustomerList,
        [TAB_BAR_TYPE.SUBSCRIPTION]: this.subscriptionCustomerList,
      };
      if (
          !this.checkedTransfer[this.currentPage] ||
          this.checkedTransfer[this.currentPage].length <
          tabRowList[this.checkedTab].length
      ) {
        this.$set(
            this.checkedTransfer,
            this.currentPage,
            tabRowList[this.checkedTab].map((row) => row.leadId)
        );
      } else {
        this.$set(this.checkedTransfer, this.currentPage, []);
      }
    },
    /**
     * 选择客户
     * @param {Number} leadId 线索id
     *  */
    pickerCustomer(leadId) {
      const checkedIds = this.checkedTransfer[this.currentPage] || [];
      const index = checkedIds.indexOf(leadId);
      if (index === -1) {
        checkedIds.push(leadId);
      } else {
        checkedIds.splice(index, 1);
      }
      this.$set(this.checkedTransfer, this.currentPage, checkedIds);
    },
    // 查看销售资源等级
    lookLeadsLevels() {
      this.$CSDialog.alert({
        title: "意向程度说明",
        width: 760,
        messageHtml: `
                <ul class="customer-levels-tips">
                    <li>A：准客户，7天内认租。</li>
                    <li>
                        B：谈判客户，已来项目查看，处于谈判中，30天内认租。
                    </li>
                    <li>C：意向客户，对项目认可，需持续跟进。</li>
                    <li>D：潜在客户，有租赁需求，需持续跟进。</li>
                    <li>E：暂无意向。</li>
                </ul>
            `,
      });
    },
    /**
     * 删除用户
     * @param {Number} leadId 销售资源线索id
     *  */
    deleteCustomer(leadId) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除吗？",
        onConfirm: () => {
          this.$fly
              .post(deleteCustomerUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                leadId,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast("删除成功");
                this.searchData();
                this.$CSDialog.instance.closeDialog();
              });
        },
      });
    },
    /**
     * 前往客户详情页面
     * @param {Number} leadId 销售线索id
     * @param {Number} tabId 目标页面中的选中的tab
     * @param {Number} sourceType 客户池标识   1：公有客户池  2：私有客户池   3：认租客户池
     * @param {Number} staffId 员工id
     *  */
    toCustomerInfoPage(leadId, tabId, sourceType, staffId = 0) {
      this.$router.push({
        name: "customerInfo",
        params: {
          leadId,
          tabId,
          sourceType,
          staffId,
        },
      });
    },
    // 点击查询按钮
    async searchData(pageNo = 1) {
      const tabActivity = {
        [TAB_BAR_TYPE.TEMPORARY_PRIVATE]: this.getTempPrivateCustomer,
        [TAB_BAR_TYPE.PUBLIC]: this.queryPublicLeadsUrl,
        [TAB_BAR_TYPE.PRIVATE]: this.queryOpportunity,
        [TAB_BAR_TYPE.SUBSCRIPTION]: this.querySubscriptionCustomer,
        [TAB_BAR_TYPE.CUSTOMERPOOLL]: this.querySitePool,
      };
      tabActivity[this.checkedTab](pageNo);
    },
    //客户池塘
    async querySitePool(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(queryPublicLeadsUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            keyword: this.queryParams.keyword,
            source: 5,
            industry: -1,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.sitePoolList = res.data.datas;

          });
    },
    /**
     * 查询公司/部门接口
     * @param {Number} level 0:代表公司
     * @param {NUmber} pageNo 分页页数
     * @param {Number} pageSize 每次查看的数量
     * @param {Number | String} parentCode 公司code
     *  */
    queryDepartmentList(
        level = ORG_LEVEL.COMPANY,
        pageNo = 1,
        pageSize = 100,
        parentCode
    ) {
      return this.$fly
          .post(queryDepartmentUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            code: "",
            name: "",
            dutyType: "",
            parentCode,
            level,
            pageNo,
            pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return [];
            }

            return res.data;
          });
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      return this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: "",
            isOnTheJob: true,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            // console.log("获取的员工信息", JSON.stringify(res.data));
            console.log(res.data);
            this.employeeList = res.data
                .map((item) => {
                  return {
                    jobTitle: item.jobTitle,
                    name: item.name,
                    id: item.id,
                  };
                })
                .filter((item) => item.jobTitle != "招商经理");
            console.log(this.employeeList);
            return res.data;
          });
    },
    // 查询公有客户池
    queryPublicLeadsUrl(pageNo = 1, pageSize = 10) {
      const {regionCode, keyword, source, industry} = this.queryParams;
      this.$fly
          .post(queryPublicLeadsUrl, {
            regionCode,
            keyword,
            source,
            industry,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.publicCustomerList = res.data.datas;
          });
    },
    // 查询私有客户
    queryOpportunity(pageNo = 1, pageSize = 10) {
      const {
        regionCode,
        keyword,
        source,
        industry,
        intention,
        orderBy,
        ownerId,
      } = this.queryParams;
      this.$fly
          .post(queryOpportunityUrl, {
            pageNo,
            pageSize,
            regionCode,
            keyword,
            source,
            industry,
            intention,
            orderBy,
            target: 2,
            ownerId,
          })
          .then((res) => {
            this.currentPage = pageNo;
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.privateCustomerList = res.data.datas;
          });
    },
    // 查询认租客户
    querySubscriptionCustomer(pageNo = 1, pageSize = 10) {
      const userInfo = this.$vc.getCurrentStaffInfo();
      const {regionCode, keyword, source, industry, ownerId} =
          this.queryParams;
      this.$fly
          .post(querySubscriptionsUrl, {
            pageNo,
            pageSize,
            regionCode,
            keyword,
            source,
            industry,
            state: 1,
            target: userInfo.jobLevel === 1 ? 2 : "",
            ownerId,
          })
          .then((res) => {
            this.currentPage = pageNo;
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                pageSize,
                currentPage: pageNo,
              });
            }
            this.subscriptionCustomerList = res.data.datas;
          });
    },
    // 获取公共客户池
    getTempPrivateCustomer(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const {keyword, source, industry, regionCode} = this.queryParams;
      this.$fly
          .post(queryTempCustomerPoolUrl, {
            keyword,
            source,
            industry,
            regionCode,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.tempPrivateCustomerList = res.data.datas;
          });
    },
    triggerChooseExcel() {
      document.querySelector("#uploadExcel").click();
    },
    chooseExcel(event) {
      return this.$vc.chooseFile(event, (file) => {
        const excelTypes = ["xlsx", "xls", "xlsm", "xlsb", "xltx", "xlt"],
            fileSplitArr = file.name.split(".");
        if (!excelTypes.includes(fileSplitArr[fileSplitArr.length - 1])) {
          this.$vc.message(`仅能上传${excelTypes.join("、")}格式的文件`);
          return;
        }
        this.uploadExcelDialog.file = file;
      });
    },
    // 打开上传excel
    openUploadExcel() {
      this.uploadExcelDialog.visible = true;
      this.uploadExcelDialog.file = {
        name: "",
      };
      this.uploadExcelDialog.source = "";
    },
    closeUploadExcel() {
      this.uploadExcelDialog.visible = false;
    },
    /**
     * 上传excel
     * @param {Object} file 文件信息
     *  */
    uploadExcel() {
      const {file, source, regionCode} = this.uploadExcelDialog;
      let formData = new FormData();
      if (file === null || file.name === "") {
        this.$vc.toast("请选择Excel");
        return;
      }
      // if (source === null || source === "") {
      //   this.$vc.toast("请选择客户来源");
      //   return;
      // }
      formData.append("file", file);
      //formData.append("source", source);
      formData.append("regionCode", regionCode);
      this.$fly
          .post(uploadExcelUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (res.data && res.data.length > 0) {
              this.$vc.message(res.data[0]);
              return;
            }
            this.$vc.toast("上传成功");
            this.closeUploadExcel();
            this.searchData();
          });
    },
    /**
     * 更改选中的tab
     * @param {Number} id 选中的tab
     * @param {Object} checkedName 更改的属性名
     *  */
    async changeTabBar(id, checkedName) {
      this[checkedName] = id;
      /* if (id != TAB_BAR_TYPE.PUBLIC && checkedName === "checkedTab") {
          this.queryParams.target = 1;
      } */
      if (id == TAB_BAR_TYPE.CONFIG) {
        Object.keys(this.crmConfig).forEach((type) => {
          this.queryCrmConfig(type);
        });
      } else {
        this.searchData();
      }
      this.checkedTransfer = {};
    },
    /**
     * 保存crm的配置
     * @param {Number} type
     */
    saveCrmConfig(type) {
      const {value} = this.crmConfig[type];
      this.$fly
          .post(saveConfigLeaseCrmUrl, {
            type,
            regionCode: this.$vc.getCurrentRegion().code,
            value,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast("设置成功");
          });
    },
    /**
     * 获取crm的配置
     * @param {Number} type
     */
    queryCrmConfig(type) {
      this.$fly
          .get(queryCrmConfigUrl, {
            type,
            regionCode: this.$vc.getCurrentRegion().code,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.crmConfig[type].value = res.data || "";
          });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import './filter-panel.styl';

.tab-view {
  padding: 30px;
  margin-top: 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 680px;

  .field-item {
    font-size: 14px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    div {
      display: inline-block;
      vertical-align: middle;

      input[type='radio'] {
        &::after {
          display: none;
        }

        &::before {
          display: none;
        }
      }
    }

    .btn {
      height: 30px;
      padding: 0 6px;
      min-width: 50px;
    }

    &-label {
      text-align: right;
      width: 112px;
      margin-right: 30px;
      color: #444;
    }

    .cs-select {
      display: inline-flex;
      border-color: #999;
      margin-right: 20px;
      --cs-select-icon: 26px;

      select {
        height: 100%;

        &.selected {
          color: #444;
        }

        option {
          color: #444;

          &:default {
            color: #999;
          }
        }
      }
    }
  }
}

/* select
width 198px */
table {
  th {
    img {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }

  th, td {
    input[type='checkbox'] {
      vertical-align: middle;
      margin-right: 10px;
      width: 20px;
      height: 20px;

      & + span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.upload-excel-dialog {
  padding: 27px 30px 30px 30px;

  .form-item {
    font-size: 24px;

    &-label {
      width: 108px;
      margin-right: 40px;
      text-align: right;
      color: #000;
    }

    &:not(:last-of-type) {
      margin-bottom: 23px;
    }

    div {
      display: inline-block;
      vertical-align: middle;

      .btn-primary {
        width: 80px;
        height: 40px;
        padding: 0;
        line-height: 40px;
        font-size: 24px;
        border-radius: 6px;
        vertical-align: middle;
      }
    }

    .cs-input {
      width: 250px;
      border: 1px solid #979797;
      border-radius: 4px;
      vertical-align: middle;

      &::placeholder {
        color: #999;
      }
    }

    /* rgb(170, 170, 170) */

    .cs-select {
      display: flex;
      --icon-line-height: 40px;

      select {
        width: 238px;
        height: 100%;
      }
    }
  }
}
</style>
